import { setComponentSettings } from '@wix/ambassador-app-settings-v1-settings/http';
import { WIX_ACCESSIBILITY_WIZARD } from '@wix/app-definition-ids';
import { createHeaders } from '@wix/headers';
import { HttpClient } from '@wix/http-client';
import type {
  ComponentAppSettings,
  SetComponentAppSettingsResponse,
} from '../appSettingsService.types';

export async function setComponentAppSettings(
  signedInstance: string,
  settings: ComponentAppSettings,
): Promise<SetComponentAppSettingsResponse> {
  const headers = createHeaders({
    signedInstance,
  });
  const httpClient = new HttpClient({
    headers,
  });

  const { data } = await httpClient.request(
    setComponentSettings({
      componentId: WIX_ACCESSIBILITY_WIZARD,
      settings,
    }),
  );
  return data as SetComponentAppSettingsResponse;
}
