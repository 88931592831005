import { EventType } from '@wix/platform-editor-sdk';

export interface IEditorEventsService {
  subscribe(events: EventType[], callback: () => void): void;
  unsubscribe(events: EventType[], callback: () => void): void;
  send(event: EventType): void;
}

export type EventSubscriptions = Record<EventType, (() => void)[]>;

export const siteChangingEvents: EventType[] = [
  EventType.anyComponentAddedToStage,
  EventType.componentDataChanged,
  EventType.componentPropsChanged,
  EventType.componentBehaviorChanged,
  EventType.componentCropSaved,
  EventType.componentDesignChanged,
  EventType.componentStyleChanged,
  EventType.pageBackgroundChanged,
  EventType.componentAnimationChanged,
  EventType.componentArrangementChanged,
  EventType.componentRotateEnded,
  EventType.componentDragEnded,
  EventType.hideMobileElement,
  EventType.showMobileElement,
  EventType.mobileTextScaleChanged,
  EventType.toggleBackToTopButtonOn,
  EventType.componentResizeStarted,
  EventType.componentDeleted,
  EventType.connectedComponentDuplicated,
  EventType.pageDeleted,
  EventType.globalDesignPresetChanged,
  EventType.pageRenamed,
];
