import { getComponentSettings } from '@wix/ambassador-app-settings-v1-settings/http';
import { WIX_ACCESSIBILITY_WIZARD } from '@wix/app-definition-ids';
import { createHeaders } from '@wix/headers';
import { HttpClient } from '@wix/http-client';
import type { GetComponentAppSettingsResponse } from '../appSettingsService.types';

export async function getComponentAppSettings(
  signedInstance: string,
): Promise<GetComponentAppSettingsResponse> {
  const headers = createHeaders({
    signedInstance,
  });
  const httpClient = new HttpClient({
    headers,
  });

  const { data } = await httpClient.request(
    getComponentSettings({
      componentId: WIX_ACCESSIBILITY_WIZARD,
    }),
  );
  return data as GetComponentAppSettingsResponse;
}
